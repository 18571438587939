const Menu = [
  {
    heading: "menu",
    route: "/employee",
    pages: [
      // {
      //   heading: "New NID Employee",
      //   route: "/employee/new-nid",
      //   fontIcon: "fa-user",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      {
        heading: "New Employee",
        route: "/employee/new-employee",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Employee List",
        route: "/employee/employee-list",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
    ],
  },
];

export default Menu;
